.content {
    padding: 0 20px;
}

.dataLine {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 0;
    gap: 20px;
}

.dataLine .lineDecoration {
    background-image: url('/public/CentralProgressComponent.svg');
    width: 108px;
    height: 108px;
}

.dataLine:first-of-type .lineDecoration {
    background-image: url('/public/EdgeProgressComponent.svg');
}

.dataLine:last-of-type .lineDecoration {
    background-image: url('/public/EdgeProgressComponent.svg');
    transform: rotate(180deg);
}

.textFrame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 38px;
    gap: 5px;

    flex: 1;
}

.title {
    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;
    /*text-align: center;*/
    text-decoration-line: underline;
    text-transform: capitalize;

    color: #FFFFFF;
}

.specializationIcon {
    background-image: url('/public/CheckMark.svg');
    width: 21px;
    height: 16.91px;
}

.placeIcon {
    background-image: url('/public/MapMark.svg');
    width: 10px;
    height: 15px;
}

.iconRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 5px;
}

.iconRow p {
    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */

    display: flex;
    flex: 1;
    align-items: center;
    text-transform: capitalize;

    color: #9F9F9F;
}

@media only screen and (max-width: 1000px) {
    .textFrame {
        margin-top: 57px;
        gap: 2px;
    }

    .dataLine .lineDecoration {
        background-image: url('/public/CentralProgressComponent145.svg');

        width: 145px;
        height: 145px;
    }

    .dataLine:first-of-type .lineDecoration {
        background-image: url('/public/EdgeProgressComponent145.svg');
    }

    .dataLine:last-of-type .lineDecoration {
        background-image: url('/public/EdgeProgressComponent145.svg');
    }

    .title {
        font-size: 24px;
    }

    .iconRow p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 700px) {
    .title {
        font-size: 20px;
    }

    .iconRow p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 610px) {
    .content {
        padding: 0 10px;
    }

    .title {
        font-size: 20px;
    }

    .iconRow p {
        font-size: 16px;
    }

    .dataLine {
        gap: 10px;
    }
}


@media only screen and (max-width: 580px) {
    .textFrame {
        margin-top: 45px;
        gap: 2px;
    }

    .dataLine {
        padding: 0;
        height: 117px;
    }

    .dataLine .lineDecoration {
        background-image: url('/public/CentralProgressComponent.svg');
        width: 108px;
        height: 108px;
    }

    .dataLine:first-of-type .lineDecoration {
        background-image: url('/public/EdgeProgressComponent.svg');
    }

    .dataLine:last-of-type .lineDecoration {
        background-image: url('/public/EdgeProgressComponent.svg');
        transform: rotate(180deg);
    }

    .title {
        font-size: 16px;
    }

    .iconRow p {
        font-size: 13px;
    }
}

@media only screen and (max-width: 480px) {
    .textFrame {
        margin-top: 38px;
        gap: 2px;
    }

    .dataLine {
        padding: 0;
        height: 98px;
    }

    .dataLine .lineDecoration {
        background-image: url('/public/CentralProgressComponentMini.svg');
        width: 90px;
        height: 90px;
    }

    .dataLine:first-of-type .lineDecoration {
        background-image: url('/public/EdgeProgressComponentMini.svg');
    }

    .dataLine:last-of-type .lineDecoration {
        background-image: url('/public/EdgeProgressComponentMini.svg');
        transform: rotate(180deg);
    }

    .title {
        font-size: 14px;
    }

    .iconRow p {
        font-size: 11px;
    }
}

@media only screen and (max-width: 410px) {
    .dataLine {
        padding: 0;
        height: 104px;
    }
}

@media only screen and (max-width: 360px) {
    .dataLine {
        padding: 15px 0;
        height: min-content;
    }

    .textFrame {
        margin-top: 0;
        gap: 3px;
    }

    .dataLine .lineDecoration {
        display: none;
    }
}
