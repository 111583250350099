.card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 90px;
    gap: 100px;

    background: #1E1E1E;
    box-shadow: 0 0 20px #1E1E1E;
    border-radius: 90px;
    flex-wrap: nowrap;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 100px;
}

.languageCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 10px;
}

.title {
    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    color: #FFFFFF;
}

.separator {
    width: 150px;
    height: 0;
    border: 2px solid #F8BC45;
}

.mark {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    gap: 7px;
}

.mark div {
    font-family: 'Times New Roman', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;

    color: #000000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 48px;
    height: 48px;

    background: #9F9F9F;
    border-radius: 24px;
    user-select: none;
}

.mark div.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 10px;

    width: 48px;
    height: 48px;

    background: #FFFFFF;
    box-shadow: 0 0 7px rgba(255, 255, 255, 0.5);
    border-radius: 24px;
}

.additionalText {
    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

@media only screen and (max-width: 1150px) {
    .card {
        flex-wrap: wrap;
        width: min-content;
    }
}

@media only screen and (max-width: 1000px) {
    .card {
        border-radius: 80px;
        gap: 80px;
    }

    .column {
        gap: 80px;
    }

    .separator {
        width: 130px;
        border: 3px solid #F8BC45;
    }

    .mark div {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        font-size: 32px;
    }

    .mark div.active {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        font-size: 32px;
    }

    .additionalText {
        font-size: 20px;
    }
}

@media only screen and (max-width: 650px) {
    .card {
        border-radius: 13vw;
        gap: 13vw;
    }

    .column {
        gap: 13vw;
    }

    .card {
        margin: 0 5%;
        padding: 15% 0;
        width: 90%;
    }

    .languageCard {
        gap: 1.5vw;
    }

    .title {
        font-size: 5.5vw;
    }

    .separator {
        width: 20vw;
        border: 0.4vw solid #F8BC45;
    }

    .mark {
        gap: 1vw;
    }

    .mark div {
        width: 9vw;
        height: 9vw;

        font-size: 5vw;
    }

    .mark div.active {
        width: 9vw;
        height: 9vw;

        font-size: 5vw;
    }

    .additionalText {
        font-size: 3vw;
    }
}

@media only screen and (max-width: 360px) {
    .additionalText {
        font-size: 3.5vw;
    }
}