.block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    gap: 100px;
}

.title {
    height: 32px;

    font-family: 'Source Code Pro', system-ui;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    /* identical to box height, or 32px */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

@media only screen and (max-width: 1000px) {
    .block {
        gap: 80px;
        padding: 100px 0;
    }
}

@media only screen and (max-width: 600px) {
    .title {
        font-size: 30px;
    }
}

@media only screen and (max-width: 550px) {
    .block {
        gap: 60px;
        padding: 60px 0;
    }

    .title {
        font-size: 24px;
    }
}

@media only screen and (max-width: 450px) {
    .title {
        font-size: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .block {
        gap: 30px;
        padding: 30px 0;
    }

    .title {
        font-size: 16px;
    }
}