.console {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    width: 60%;
    max-width: 900px;
    height: 300px;

    background: #000000;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.25);
    border-radius: 13px;

    overflow: auto;
}

.topBar {
    width: 100%;
    height: 27px;

    background: #3A373C;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px;
    gap: 7px;

    width: fit-content;
    height: fit-content;
    left: 9px;
    top: 8px;
}

.button {
    width: 12px;
    height: 12px;
    border-radius: 50%;

    background: white;
    transition: filter 0.3s
}

.button.red {
    background: #EE5C54;
}

.button.yellow {
    background: #F8BC45;
}

.button.green {
    background: #5DC942;
}

.button:hover {
    filter: brightness(85%);
}

.content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    gap: 10px;

    width: calc(100% - 30px);
    height: 100%;

    background: #1E1E1E;
}

.text {
    width: 100%;
    margin: 0;

    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #FFFFFF;
}

@media only screen and (max-width: 1000px) {
    .console {
        height: 200px;
    }

    .text {
        font-size: 20px;
    }
}

@media only screen and (max-width: 730px) {
    .console {
        height: 150px;
        border-radius: 11px;
    }

    .content {
        padding: 10px;
        width: calc(100% - 20px);
    }

    .topBar {
        height: 22px;
    }

    .buttons {
        gap: 4px;
    }

    .button {
        width: 9px;
        height: 9px;
    }

    .text {
        font-size: 16px;
    }
}

@media only screen and (max-width: 500px) {
    .console {
        height: 120px;
        border-radius: 9px;
    }

    .content {
        padding: 8px;
        width: calc(100% - 16px);
    }

    .topBar {
        height: 18px;
    }

    .buttons {
        padding: 5px;
        top: 2px;
        gap: 4px;
    }

    .button {
        width: 7px;
        height: 7px;
    }

    .text {
        font-size: 14px;
    }
}

@media only screen and (max-width: 420px) {
    .text {
        font-size: 12px;
    }
}

@media only screen and (max-width: 360px) {
    .console {
        height: 100px;
        border-radius: 7px;
    }

    .content {
        padding: 6px;
        width: calc(100% - 12px);
    }

    .topBar {
        height: 18px;
    }

    .buttons {
        padding: 4px;
        top: 2px;
        gap: 3px;
    }

    .button {
        width: 6px;
        height: 6px;
    }

    .text {
        font-size: 10px;
    }
}

@media only screen and (max-width: 300px) {
    .console {
        width: 63%;
        height: 90px;
    }

    .text {
        font-size: 8px;
    }
}
