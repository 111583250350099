.menuItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: 24px;

    background: #00D2B2;
    border-width: 0 0 2px 2px;
    border-style: solid;
    border-color: #00A68D;
    border-radius: 4px;
}

.menuItemButton {
    background: none;
    border: none;
    cursor: pointer;

    font-family: 'Source Code Pro', system-ui;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    padding: 0 12px;
    /* identical to box height, or 24px */

    text-align: center;
    text-transform: uppercase;

    color: #262626;
    user-select: none;
}

@media only screen and (max-width: 900px) {
    .menuItem {
        height: 22px;
    }

    .menuItemButton {
        font-size: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .menuItemButton {
        font-size: 19px;
    }
}

@media only screen and (max-width: 730px) {
    .menuItemButton {
        padding: 0 6px;
    }
}
