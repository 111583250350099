.menu {
    list-style-type: none;
    margin: 0;

    display: flex;
    z-index: 9999;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 0;
    gap: 3px;

    position: fixed;
    width: 100%;
    height: min-content;
    left: 0;
    top: 0;

    background: #262626;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 663px) {
    .menu {
        display: none;
    }
}