.footerBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 100px;
    gap: 20px;

    border-top: 1px solid #F8BC45;
}

.footerBlock p {
    /*height: 32px;*/

    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    color: #FFFFFF;
}

@media only screen and (max-width: 650px) {
    .footerBlock {
        padding: 50px 0;
        gap: 10px;
    }

    .footerBlock p {
        font-size: 24px;
    }
}

@media only screen and (max-width: 500px) {
    .footerBlock {
        padding: 30px 0;
        gap: 10px;
    }

    .footerBlock p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 300px) {
    .footerBlock {
        padding: 30px 0;
        gap: 5px;
    }

    .footerBlock p {
        font-size: 14px;
    }
}
