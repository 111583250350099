.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px;
    gap: 50px;

    background: #00D2B2;
    box-shadow: 0 0 20px #00D2B2;
    border-radius: 90px;
}

.titleFrame {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 15px;

    width: 583px;
    height: 87px;
}

.titleFrame p {
    font-family: 'Source Code Pro', monospace bold;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    /* identical to box height, or 36px */

    color: #000000;
}

.contactsFrame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 20px;
}

.contactCard {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 30px;
}

.contactCard img {
    width: 64px;
    height: 64px;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    user-select: none;
}

.contactCard p {
    font-family: 'Source Code Pro', monospace bold;
    /*font-style: normal;*/
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    /* identical to box height, or 32px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}

@media only screen and (max-width: 1000px) {
    .card {
        border-radius: 80px;
    }
}

@media only screen and (max-width: 815px) {
    .card {
        padding: 60px 40px;
    }
}

@media only screen and (max-width: 720px) {
    .card {
        width: 80vw;
        padding: 11vw 5.8vw;
        gap: 6.8vw;
        border-radius: 12vw;
    }

    .titleFrame {
        width: fit-content;
        height: fit-content;
        gap: 2vw;
    }

    .titleFrame p {
        font-size: 4.8vw;
    }

    .contactsFrame {
        gap: 2vw;
    }

    .contactCard {
        gap: 4vw;
    }

    .contactCard p {
        font-size: 4.5vw;
    }

    .contactCard img {
        width: 9vw;
        height: auto;
    }
}
