p {
    margin: 0;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    gap: 150px;

    background: #262626;
    box-shadow: 0 0 20px #1E1E1E;
    border-radius: 60px;

    width: min-content;
}

.cardContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 0;
    gap: 150px;

    width: min-content;
}

.leftColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 27px;
}

.additionalLanguages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 30px;
}

.separator {
    width: 200px;
    height: 0;

    border: 2px solid #F8BC45;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 15px;

    width: 100%;
}

.titleText {
    /*width: 375px;*/
    height: 24px;
    margin: 0;

    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;
    text-align: center;
}

.progressCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 15px;

    height: 70px;
}

.progressCardImage {
    width: 70px;
    height: auto;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    user-select: none;
}

.progressCardProgressBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 24px;

    width: min-content;
    /*height: 60px;*/
}

.progressCardProgressBarGraphics {
    position: absolute;
    width: 397px;
    height: 24px;
    left: 0;
    top: 0;

    background: #FFFFFF;
    border-width: 0 0 3px 3px;
    border-style: solid;
    border-color: #9F9F9F;
    border-radius: 6px;
}

.progressCardProgressFillerGraphics {
    position: absolute;
    height: 20px;
    left: 5px;
    top: 2px;

    background: #0E825D;
    border-radius: 3px;
}

.progressCardProgressBarBlock {
    position: relative;
    width: 400px;
    height: 27px;
}

.progressCardProgressTextBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 52px;

    width: 400px;
    height: 14px;
}

.progressCardProgressText {
    margin: 0 5px;

    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #FFFFFF;
}

.rightColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 167px;

    width: auto;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 10px;

    width: auto;
    height: auto;
}

.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 10px;

    width: fit-content;
    /*height: 100px;*/
}

.listItemDot {
    width: 12px;
    height: 20px;

    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.listItemContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    gap: 5px;

    width: fit-content;
    /*height: 41px;*/
}

.listItemTitle {
    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;

    display: flex;
    align-items: center;
    text-decoration-line: underline;

    color: #FFFFFF;

    width: fit-content;
    /*block-size: fit-content;*/
    /*width: 500px;*/
}

.listItemDescription {
    width: fit-content;
    /*height: 16px;*/

    font-family: 'Source Code Pro', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.additionalInformation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 30px;

    width: auto;
    height: auto;
}

@media only screen and (max-width: 1500px) {
    .cardContent {
        gap: 50px;
    }
}

@media only screen and (max-width: 1400px) {
    .card {
        padding: 90px;
    }
}

@media only screen and (max-width: 1300px) {
    .cardContent {
        flex-wrap: wrap;
        gap: 150px;
    }
}

@media only screen and (max-width: 1000px) {
    .card {
        padding: 80px 80px 100px 80px;
    }

    .cardContent {
        gap: 100px;
    }

    .rightColumn {
        gap: 60px;
    }

    .separator {
        border: 3px solid #F8BC45;
    }

    .title {
        gap: 20px;
    }

    .titleText {
        font-size: 32px;
    }

    .progressCard {
        gap: 20px;
    }

    .progressCardProgressBar {
        gap: 10px;
    }

    .progressCardProgressBarGraphics {
        height: 27px;
    }

    .progressCardProgressFillerGraphics {
        height: 23px;
    }

    .progressCardImage {
        width: 75px;
        height: 75px;
    }

    .progressCardProgressText {
        font-size: 20px;
    }

    .list {
        gap: 15px;
    }

    .listItem {
        gap: 15px;
    }

    .listItemDot {
        font-size: 32px;
    }

    .listItemTitle {
        font-size: 24px;
    }

    .listItemDescription {
        font-size: 20px;
    }
}

@media only screen and (max-width: 730px) {
    .card {
        padding: 60px 50px 50px 50px;
    }

    .titleText {
        font-size: 24px;
    }

    .progressCardProgressBarBlock {
        width: 295px;
    }

    .progressCardProgressBarGraphics {
        width: 292px;
    }

    .progressCardProgressTextBlock {
        width: 295px;
    }

    .progressCardProgressText {
        font-size: 16px;
    }

    .listItem {
        width: 450px;
    }

    .listItemDot {
        font-size: 24px;
    }

    .listItemTitle {
        font-size: 24px;
    }

    .listItemDescription {
        font-size: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .titleText {
        font-size: 20px;
    }

    .listItem {
        width: 380px;
    }

    .listItemTitle {
        font-size: 20px;
    }

    .listItemDescription {
        font-size: 16px;
    }

    .progressCardProgressText {
        font-size: 16px;
    }
}

@media only screen and (max-width: 550px) {
    .card {
        padding: 40px 40px 50px 40px;
    }

    .title {
        gap: 5px;
    }

    .separator {
        border: 2px solid #F8BC45;
        width: 150px;
    }

    .cardContent {
        gap: 50px;
    }

    .leftColumn {
        gap: 10px;
    }

    .rightColumn {
        gap: 40px;
    }

    .additionalLanguages {
        gap: 10px;
    }

    .progressCardImage {
        width: 60px;
        height: auto;
    }

    .titleText {
        font-size: 16px;
    }

    .progressCard {
        gap: 10px;
    }

    .progressCardProgressBar {
        gap: 5px;
    }

    .progressCardProgressBarBlock {
        width: 245px;
        height: 25px;
    }

    .progressCardProgressBarGraphics {
        width: 242px;
        height: 23px;
    }

    .progressCardProgressFillerGraphics {
        height: 19px;
        left: 5px;
    }

    .progressCardProgressTextBlock {
        width: 245px;
    }

    .progressCardProgressText {
        font-size: 14px;
    }

    .listItem {
        width: 320px;
        gap: 5px;
    }

    .listItemDot {
        font-size: 20px;
    }

    .listItemTitle {
        font-size: 16px;
    }

    .listItemDescription {
        font-size: 14px;
    }
}

@media only screen and (max-width: 450px) {
    .titleText {
        font-size: 14px;
    }

    .separator {
        width: 130px;
    }

    .progressCardImage {
        width: 50px;
        height: auto;
    }

    .progressCardProgressBarBlock {
        width: 195px;
        height: 23px;
    }

    .progressCardProgressBarGraphics {
        width: 192px;
        height: 19px;
    }

    .progressCardProgressFillerGraphics {
        height: 15px;
        left: 5px;
    }

    .progressCardProgressTextBlock {
        width: 195px;
    }

    .listItem {
        width: 250px;
    }

    .listItemDot {
        font-size: 14px;
    }

    .listItemTitle {
        font-size: 12px;
    }

    .listItemDescription {
        font-size: 11px;
    }

    .progressCardProgressText {
        font-size: 11px;
    }

    .progressCardProgressBar {
        gap: 3px;
    }
}

@media only screen and (max-width: 400px) {
    .card {
        border-radius: 40px;
        padding: 25px 25px 35px 25px;
    }

    .titleText {
        font-size: 12px;
    }

    .rightColumn {
        gap: 20px;
    }

    .progressCardProgressBar {
        gap: 0;
        margin-top: 10px;
    }

    .progressCard {
        height: 45px;
    }

    .progressCardImage {
        width: 40px;
        height: auto;
    }

    .progressCardProgressBarBlock {
        width: 145px;
        height: 23px;
    }

    .progressCardProgressBarGraphics {
        width: 142px;
    }

    .progressCardProgressFillerGraphics {
        height: 15px;
        left: 4px;
    }

    .progressCardProgressTextBlock {
        width: 145px;
    }

    .list {
        gap: 7px;
    }

    .listItem {
        width: 200px;
    }

    .listItemTitle {
        font-size: 12px;
    }

    .listItemDescription {
        font-size: 10px;
    }

    .progressCardProgressText {
        font-size: 10px;
    }

    .additionalInformation {
        gap: 10px;
    }
}
