.welcomeBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-top: 100px;
    padding-left: 0;
    padding-right: 0;
    gap: 5%;

    position: relative;
    width: 100%;
    height: 270px;
    left: 0;
    top: 0;
}

.myImage {
    position: relative;
    z-index: 0;

    /*width: 257px;*/
    height: 270px;

    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
    user-select: none;
}

@media only screen and (max-width: 1000px) {
    .welcomeBlock {
        padding-top: 80px;
        height: 180px;
    }

    .myImage {
        height: 180px;
    }
}

@media only screen and (max-width: 730px) {
    .welcomeBlock {
        padding-top: 60px;
        height: 130px;
    }

    .myImage {
        height: 130px;
    }
}

@media only screen and (max-width: 500px) {
    .welcomeBlock {
        padding-top: 40px;
        height: 110px;
    }

    .myImage {
        height: 110px;
    }
}

@media only screen and (max-width: 360px) {
    .welcomeBlock {
        padding-top: 40px;
        height: 90px;
    }

    .myImage {
        height: 90px;
    }
}

@media only screen and (max-width: 300px) {
    .welcomeBlock {
        padding-top: 40px;
        height: 80px;
        gap: 3%;
    }

    .myImage {
        height: 80px;
    }
}
